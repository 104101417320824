import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "gatsby-plugin-react-i18next";

export const Footer: React.FC = () => {
  const {t} = useTranslation();
  const itemsSkeptron = [1, 2, 3, 4, 5];
  const itemsHyperion = [1, 2];

  return (
    <footer>
      <div className="request">
        <h3>{t('common.request.heading')}</h3>
        <Link to="/contact" className="button">{t('common.request.button')}</Link>
      </div>
      <div className="footer-content">
      <div className="footer-top">
        <div className="footer-block">
          {/*<Logo />*/}
          <p className="logo">{t('footer.logo.description')}</p>
          <h3 className="telephone">210 9400606-7</h3>
          <p>{t('footer.logo.information')}</p>
          <ul className="social-list">
            <li className="facebook"><a href="#">Facebook</a></li>
            <li className="twitter"><a href="#">Twitter</a></li>
            <li className="youtube"><a href="#">Youtube</a></li>
            </ul>
        </div>
        <div className="footer-block">
          <nav>
            <h3>{t('footer.quick_links.title')}</h3>
            <ul>
              {/*<li><Link to="/sterilization/">{t('footer.quick_links.sterilization')}</Link></li>*/}
              <li><Link to="/faq/">{t('footer.quick_links.faq')}</Link></li>
              <li><Link to="/company/">{t('footer.quick_links.company')}</Link></li>
              <li><Link to="/contact/">{t('footer.quick_links.contact')}</Link></li>
            </ul>
          </nav>
        </div>

        <div className="footer-block">
          <nav>
            <h3><Link to="/products/skeptron/">{t('footer.products.skeptron_title')}</Link></h3>
            <ul>
              {
                itemsSkeptron.map((item, index) => {
                  return (
                    <li key={index}><Link
                      to={t(`footer.products.skeptron.${index}.link`)}>{t(`footer.products.skeptron.${index}.title`)}</Link>
                    </li>
                  )
                })
              }
            </ul>
          </nav>
        </div>

        <div className="footer-block">
          <nav>
            <h3><Link to="/products/hyperion/">{t('footer.products.hyperion_title')}</Link></h3>
            <ul>
              {
                itemsHyperion.map((item, index) => {
                  return (
                    <li key={index}><Link
                      to={t(`footer.products.hyperion.${index}.link`)}>{t(`footer.products.hyperion.${index}.title`)}</Link>
                    </li>
                  )
                })
              }
            </ul>
          </nav>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="copyright">© Copyright H rendev. All rights reserved</p>
      </div>
      </div>
    </footer>
  );
}
