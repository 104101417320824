import React from "react"
import {Header} from "../header/header";
import {Footer} from "../footer/footer";
import "./normal_layout.scss";

interface LayoutProps {
  type?: string;
}

export const NormalLayout: React.FC<LayoutProps> = (
    {
      children,
      type,
      ...rest
    }) => {
  return (
      <div className={`page ${type? `${type}`: `simple`}`}>
        <Header/>
        <main>
          {children}
        </main>
        <Footer/>
      </div>
  )
}
