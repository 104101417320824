import React, { useState, useCallback, SyntheticEvent } from "react"
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { LanguageMenu } from './language_menu';

type Props = {
  toggleMenu: () => void
};

const PhoneLang: React.FC<{ className?: string }> = ({ className = '' }) => (
  <div className={`phone-lang ${className}`}>
    <p className="telephone">T. 210 9400606-7</p>
    {/*<LanguageMenu/>*/}
  </div>
);

const Menu: React.FC<Props> = ({ toggleMenu }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const toggleSubMenu = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setExpanded(state => !state);
    return false;
  }

  return (
      <nav role="navigation">
        <h1 className="logo"><Link className="logo" to="/">UV Tech by RENDEV</Link></h1>
        <button className="menu-btn" onClick={toggleMenu}>
          Open
        </button>

        <ul className="menu">
          <li className="has-child">
            <a href="#" onClick={toggleSubMenu}>{t("header.navigation.products.name")}</a>
            <div className={`child ${expanded ? 'expanded' : ''}`}>
              <ul className="submenu">
                <li className="category"><Link to="/products/skeptron">{t("header.navigation.products.skeptron.line")}</Link></li>
                <li><Link to="/products/skeptron/hybrid">{t("header.navigation.products.skeptron.hybrid")}</Link></li>
                <li><Link to="/products/skeptron/skeptron_l">{t("header.navigation.products.skeptron.l")}</Link></li>
                <li><Link to="/products/skeptron/skeptron_t">{t("header.navigation.products.skeptron.t")}</Link></li>
                <li><Link to="/products/skeptron/car">{t("header.navigation.products.skeptron.car")}</Link></li>
                <li><Link to="/products/skeptron/pro">{t("header.navigation.products.skeptron.pro")}</Link></li>
              </ul>
              <ul className="submenu">
                <li className="category"><Link to="/products/hyperion">{t("header.navigation.products.hyperion.line")}</Link></li>
                <li><Link to="/products/hyperion/hyperion_s">{t("header.navigation.products.hyperion.s")}</Link></li>
                <li><Link to="/products/hyperion/office">{t("header.navigation.products.hyperion.office")}</Link></li>
              </ul>
            </div>
          </li>
          {/*<li><Link to="/sterilization">{t("header.navigation.sterilization")}</Link></li>*/}
          <li><Link to="/faq">{t("header.navigation.faq")}</Link></li>
          <li><Link to="/company">{t("header.navigation.company")}</Link></li>
          <li className="contact"><Link to="/contact">{t("header.navigation.contact")}</Link></li>
        </ul>
        <PhoneLang className="mobile" />
      </nav>
  );
};

export const Header: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const toggleMenu = useCallback(() => setExpanded(state => !state), []);
  return (
      <header className={`header ${expanded ? 'expanded' : ''}`}>
        <div className="upper-header">
          <PhoneLang className="desktop" />
        </div>
        <Menu
          toggleMenu={toggleMenu}
        />
      </header>
  );
};
